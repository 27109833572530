import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Route } from "@angular/router";
import { AppTabService } from "../../shared/services";
import { SettingsService } from "../../shared";
import { debounceTime, first } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-app-tab-sequence",
  templateUrl: "./app-tab-sequence.component.html",
  styleUrls: ["./app-tab-sequence.component.scss"]
})
export class AppTabSequenceComponent implements OnInit {
  private _success = new Subject<string>();

  //alerts
  errorMessage = null;
  successMessage = null;
  settingResult: any;

  setting: any = {
    selection: "",
    firstoption: "",
    secondoption: "",
    thirdoption: ""
  };
  tabs: any = [];

  examId: any = ''

  model: any = {
    examid: "",
    selectedTabsSequence: [
      {
        "code": "CAND_INSTRUCTIONS",
        "name": "Candidate Instructions",
        "display": "Candidate Instructions",
        "active": false,
        "sequence": 0,
      },
      {
        "code": "CAND_EXAMSELECTION",
        "name": "Exam Selection",
        "display": "Exam Selection",
        "active": true,
        "sequence": 1,
      },
      {
        "code": "CAND_PERSONAL",
        "name": "Personal Details",
        "display": "Personal Details",
        "active": false,
        "sequence": 2,
      },
      {
        "code": "CAND_EDUCATION",
        "name": "Education Details",
        "display": "Education Details",
        "active": false,
        "sequence": 3,
      },
      {
        "code": "CAND_OCCUPATION",
        "name": "Occupational Details",
        "display": "Occupational Details",
        "active": false,
        "sequence": 4,
      },
      {
        "code": "CAND_DOCUMENTS",
        "name": "Document Upload",
        "display": "Document Upload",
        "active": false,
        "sequence": 5,
      },
      {
        "code": "CAND_ELIGIBILITY",
        "name": "Eligibility Criteria",
        "display": "Eligibility Criteria",
        "active": false,
        "sequence": 6,
      },
      {
        "code": "CAND_TESTCENTER",
        "name": "Test Center",
        "display": "Test Center",
        "active": false,
        "sequence": 7,
      },
      {
        "code": "CAND_PREVIEW",
        "name": "Preview",
        "display": "Preview",
        "active": false,
        "sequence": 8,
      }
    ]
  };

  constructor(
    private route: ActivatedRoute,
    private appTabService: AppTabService,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {

    this.getExamId();

    this.getSetting();

    this.initAlert();
    this.appTabService
      .loadAll()
      .pipe(first())
      .subscribe(res => {
        this.tabs = res;
        this.tabs.sort(function (a, b) {
          if (a.sequence > b.sequence) {
            return 1;
          }
          if (a.sequence < b.sequence) {
            return -1;
          }
          return 0;
        });
      });

  }
  initAlert() {
    this._success.subscribe(message => (this.successMessage = message));
    this._success
      .pipe(debounceTime(2000))
      .subscribe(() => (this.successMessage = null));
  }

  getExamId() {
    this.route.paramMap.subscribe(params => {
      this.examId = params.get("id");
      this.getTabs();
    });
  }

  getTabs() {
    this.appTabService.loadAllTabsByExamId(this.examId).pipe(first()).subscribe(res => {
      if (!res) {
        return console.log("tab entry not found", res);
      }

      this.model = res;
      this.model.selectedTabsSequence.sort(function (a, b) {
        if (a.sequence > b.sequence) {
          return 1;
        }
        if (a.sequence < b.sequence) {
          return -1;
        }
        return 0;
      });


    })
  }

  onCheckChange(tab) {
    let seq = tab.sequence;
    let currTabIndex = this.model.selectedTabsSequence.findIndex(el => el.sequence == seq)

    if (this.model.selectedTabsSequence[currTabIndex].active) {
      this.model.selectedTabsSequence[currTabIndex].active = false;
    }else {
      this.model.selectedTabsSequence[currTabIndex].active = true;
    }


    // this.appTabService
    //   .handleActive(tab)
    //   .pipe(first())
    //   .subscribe(res => { });
  }

  up(tab) {
    let seq = tab.sequence;
    if (seq == 0) return ;

    let currTabIndex = this.model.selectedTabsSequence.findIndex(el => el.sequence == seq)
    let upTabIndex = this.model.selectedTabsSequence.findIndex(el => el.sequence == seq - 1)

    this.model.selectedTabsSequence[currTabIndex].sequence = seq - 1;
    this.model.selectedTabsSequence[upTabIndex].sequence = seq;

    let temp = this.model.selectedTabsSequence[currTabIndex]
    this.model.selectedTabsSequence[currTabIndex] = this.model.selectedTabsSequence[upTabIndex];
    this.model.selectedTabsSequence[upTabIndex] = temp;
  }

  down(tab) {
    let seq = tab.sequence;
    if (this.model.selectedTabsSequence.length - 1 == seq) return ;

    let currTabIndex = this.model.selectedTabsSequence.findIndex(el => el.sequence == seq)
    let downTabIndex = this.model.selectedTabsSequence.findIndex(el => el.sequence == seq + 1)

    this.model.selectedTabsSequence[currTabIndex].sequence = seq + 1;
    this.model.selectedTabsSequence[downTabIndex].sequence = seq;

    let temp = this.model.selectedTabsSequence[currTabIndex]
    this.model.selectedTabsSequence[currTabIndex] = this.model.selectedTabsSequence[downTabIndex];
    this.model.selectedTabsSequence[downTabIndex] = temp;
  }

  moveUp(tab) {
    var seq = tab.sequence;
    console.log(tab)
    console.log(this.model)

    for (var i = 0; i < this.model.selectedTabsSequence.length; i++) {
      if (this.model.selectedTabsSequence[i].sequence == seq - 1) {
        let currTabIndex = this.model.selectedTabsSequence.findIndex(el => el.sequence == seq)
        this.model.selectedTabsSequence[i].sequence = seq;
        this.model.selectedTabsSequence[currTabIndex].sequence = seq - 1;
        console.log(this.model)


        // tab.sequence = seq - 1;
        // this.appTabService
        //   .handleSequence(tab, this.model.selectedTabsSequence[i])
        //   .pipe(first())
        //   .subscribe(res => { });
        break;
      }
    }
  }

  moveDown(tab) {
    var seq = tab.sequence;

    for (var i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].sequence == seq + 1) {
        this.tabs[i].sequence = seq;
        tab.sequence = seq + 1;
        this.appTabService
          .handleSequence(tab, this.tabs[i])
          .pipe(first())
          .subscribe(res => { });
        break;
      }
    }
  }

  save () {
    this.model.examid = this.examId
    this.model.selectedTabsSequence.forEach(el => el.created = new Date())
    this.appTabService.saveTabs(this.model).pipe(first()).subscribe(res => {
      this.getExamId();
    })
  }

  onSave() {
    this.settingsService
      .saveSettings(this.setting)
      .pipe(first())
      .subscribe(res => {
        this._success.next("content saved..");
        this.getSetting();
      });
  }

  getSetting() {
    this.settingsService
      .getSettings()
      .pipe(first())
      .subscribe(res => {
        this.setting = res;
      });
  }
}
